/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventType, InteractionRequiredAuthError } from '@azure/msal-browser';
import { Actions, OnInitEffects, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { catchError, exhaustMap, from, map, skipWhile, switchMap, take, tap, withLatestFrom } from 'rxjs';
import { CommonService } from 'src/app/pages/shared/services/common.service';
import { thrower } from 'src/common/commonMethods';
import { getSyncDetailsActions } from '../sync/actions.sync';
import { lastCheckTimeUserActions, userActions } from '../users/actions.users';
import { lastVersionActions } from '../version/actions.version';
import { logOutActions, microsoftActiveDirectoryLogin, startApplicationAction, strapiLoginActions } from './actions.auth';
import { selectMicrosoftToken } from './selectors.auth';
import { userRoleListActions } from '../user-roles/action.userRole';

@Injectable()
export class AuthEffect implements OnInitEffects {

  // startApplicationLocalStorage$ = createEffect(() => this.actions$.pipe(
  //   ofType(startApplicationAction),
  //   tap(() => {
  //     const storedEmail = localStorage.getItem('userEmail');
  //     if (storedEmail) {
  //       this.store.dispatch(userActions.tryGetUser({
  //         filter: {
  //           email: storedEmail
  //         }
  //       }));
  //     } else {
  //       this.router.navigate(['/login']);
  //     }
  //   })
  // ), { dispatch: false });

  startApplication$ = createEffect(() => this.actions$.pipe(
    ofType(startApplicationAction),
    switchMap(() => this.msalBroadcastService.msalSubject$),
    skipWhile((val) => val.eventType !== EventType.HANDLE_REDIRECT_END),
    take(1),
    map(() => {
      const accounts = this.msalService.instance.getAllAccounts();
      accounts.length > 0 ? this.msalService.instance.setActiveAccount(accounts[0]) : thrower('no account provided');
      return this.msalService.instance.getActiveAccount();
    }),
    exhaustMap((account) => from(this.msalService.instance.acquireTokenSilent({ scopes: [], account: account ? account : undefined, forceRefresh: false }))),
    tap(microsoftToken => microsoftToken !== null ?
      this.store.dispatch(microsoftActiveDirectoryLogin.microsoftActiveDirectoryLoginSuccess({ microsoftToken })) : ''),

    catchError((error, caught) => {
      if (error instanceof InteractionRequiredAuthError) {
        const accounts = this.msalService.instance.getAllAccounts();
        if (accounts.length > 0) {
          const request = {
            scopes: [],
            loginHint: accounts[0].username // For v1 endpoints, use upn from idToken claims
          };
          this.msalService.instance.acquireTokenRedirect(request);
        }
      }
      return caught;
    })
  ), { dispatch: false });

  MS$ = createEffect(() => this.actions$.pipe(
    ofType(microsoftActiveDirectoryLogin.microsoftActiveDirectoryLogin),
    exhaustMap(() =>
      // this.msalService.initialize().pipe(switchMap(() => // here => don't remove this line
      this.msalService.loginPopup()
      // ))
    ),
    tap(({ account }) => this.msalService.instance.setActiveAccount(account)),
    map((microsoftToken: AuthenticationResult) =>
      microsoftActiveDirectoryLogin.microsoftActiveDirectoryLoginSuccess({ microsoftToken })
    ),
    catchError((error, caught) => {
      this.store.dispatch(microsoftActiveDirectoryLogin.microsoftActiveDirectoryFailure({ error }));
      return caught;
    })
  ));

  linkMStoStrapiLogin$ = createEffect(() => this.actions$.pipe(
    ofType(microsoftActiveDirectoryLogin.microsoftActiveDirectoryLoginSuccess),
    map(({ microsoftToken: { accessToken } }) => strapiLoginActions.strapiLogin({ accessToken }))
  ));

  straplogin$ = createEffect(() => this.actions$.pipe(
    ofType(strapiLoginActions.strapiLogin),
    exhaustMap(({ accessToken }) => this.commonService.getStrapiJWT(accessToken)),
    map((strapiToken) => strapiLoginActions.strapiLoginSuccess({ strapiToken })),
    catchError((error, caught) => {
      this.store.dispatch(strapiLoginActions.strapiLoginFailure({ error }));
      return caught;
    })
  ));

  authRedirect1 = createEffect(() => this.actions$.pipe(
    ofType(strapiLoginActions.strapiLoginSuccess),
    withLatestFrom(this.store.select(selectMicrosoftToken)),
    tap(([x, { account }]) => {
      if (account?.username) {
        let doRedirect = false;

        const returnUrl = localStorage.getItem('returnUrl');
        if (returnUrl) {
          localStorage.removeItem('returnUrl');
        }

        if (window.location.href.includes('/login') && account) {
          doRedirect = true;
        }

        this.store.dispatch(userActions.tryGetUser({
          filter: {
            email: account?.username,
          },
          doRedirect,
          displayName: account?.name
        }));

        this.store.dispatch(getSyncDetailsActions.tryGetSyncItem());
        this.store.dispatch(lastCheckTimeUserActions.tryGetLastCheckTimeUserItem());
        this.store.dispatch(lastVersionActions.tryGetLastVersion({ versionType: 'CMS' }));
        this.store.dispatch(userRoleListActions.tryGetUserRoleList());

        if (returnUrl) {
          const extractedPart = returnUrl.split('/home')[1];
          const result = `/home${extractedPart}`;

          this.router.navigate([result]);
        }

        if (window.location.href.includes('/unauthorized')) {
          this.router.navigate(['/']);
        }

      } else {
        this.router.navigate(['/login']);
      }
    })
  ), { dispatch: false });

  logout$ = createEffect(() => this.actions$.pipe(
    ofType(logOutActions.tryGetLogout),
    withLatestFrom(this.store.select(selectMicrosoftToken)),
    // skipWhile( ([account]) => { return !account;}),
    switchMap(([type, { account }]) => this.msalService.logoutRedirect({ account })),
    map(() => logOutActions.successLogout()),
    catchError((error: any, caught) => {
      this.store.dispatch(logOutActions.failureLogout({ error }));
      return caught;
    }))
  );

  constructor(
    private store: Store,
    private actions$: Actions,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private commonService: CommonService,
    private router: Router,

    // private api: ApisService,
    // public popOverCtrl: PopoverController,
    // private msalService: MsalService,
    // private msalBroadcastService: MsalBroadcastService

  ) { }

  ngrxOnInitEffects(): Action {
    return startApplicationAction();
  }
}
