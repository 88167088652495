import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, skipWhile } from 'rxjs';
import { logOutActions } from 'src/features/auth/actions.auth';
import { selectUser } from 'src/features/users/selectors.users';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent implements OnInit {
  currentUser$ = this.store.select(selectUser).pipe(
    skipWhile(response => !response),
    map(response => response)
  );
  constructor(private store: Store) { }

  ngOnInit() {
  }


  logout() {
    this.store.dispatch(logOutActions.tryGetLogout())
  }

}
