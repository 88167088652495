// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tv__emptyContent {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.tv__emptyContent img {
  height: 246px;
}
.tv__emptyContent .title {
  color: var(--NB-black);
  font-size: 1.3125rem;
  font-weight: 500;
  line-height: 2rem;
  margin-inline-end: 0.75rem;
}
.tv__emptyContent .desc {
  width: 567px;
  color: var(--NG-gray-500A, #666);
  text-align: center;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5rem;
}
.tv__emptyContent a {
  margin-block-start: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/shared/components/not-found/not-found.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,aAAA;EAEA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;AAAJ;AAII;EACI,aAAA;AAFR;AAMI;EACI,sBAAA;EAEA,oBAAA;EACA,gBAAA;EACA,iBAAA;EAEA,0BAAA;AANR;AASI;EACI,YAAA;EAEA,gCAAA;EACA,kBAAA;EACA,mBAAA;EACA,gBAAA;EACA,mBAAA;AARR;AAWI;EACI,wBAAA;AATR","sourcesContent":[".tv__emptyContent {\n    width: 100%;\n    height: 100vh;\n\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n\n   \n\n    img {\n        height: 246px;\n    }\n\n\n    .title {\n        color: var(--NB-black);\n\n        font-size: 1.3125rem;\n        font-weight: 500;\n        line-height: 2rem;\n\n        margin-inline-end: 0.75rem;\n    }\n\n    .desc {\n        width: 567px;\n\n        color: var(--NG-gray-500A, #666);\n        text-align: center;\n        font-size: 0.875rem;\n        font-weight: 400;\n        line-height: 1.5rem;\n    }\n\n    a{\n        margin-block-start: 2rem;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
