import { RoleSummary, StrapiUserRoleResponse, UserRoleDisplayNames, UserRoleEnum } from "src/app/pages/home/inner-pages/user-management/user.constant";

export const userHasRole = (_currentUserRoles: any, _typeOfRole: string): boolean => {
    if (!_currentUserRoles.length) {
        return false;
    }

    return _currentUserRoles.some(role => role?.role_name === _typeOfRole);
}

export const fillUserRoles = (_userRoles: StrapiUserRoleResponse): RoleSummary[] => {
    const _nameOfRoles: RoleSummary[] = [];

    _userRoles.data.forEach(role => {
        const attributes = role.attributes;

        _nameOfRoles.push({
            id: role.id,
            role_name: attributes.role_name,
        });
    });

    return _nameOfRoles;
}

export const transformTheNameOfRoles = (_userRoles: any[]) => {

    return _userRoles.map(role => ({
        ...role,
        displayName: UserRoleDisplayNames[role.role_name]
    }));
}


// Check if the user can access the campaign
export function canAccessCampaign(_currentUserRoles: any): boolean {
    if (!_currentUserRoles.length) {
        return false;
    }
    const campaignComponentsRoles = [
        UserRoleEnum.SystemAdmin,
        UserRoleEnum.ContentCreator,
        UserRoleEnum.ContentManager,
        UserRoleEnum.ContentApprover,
    ];
    return _currentUserRoles.some(role => campaignComponentsRoles.includes(role.role_name));
}

// Check if the user can access the settings
export function canAccessSettings(_currentUserRoles: any): boolean {
    if (!_currentUserRoles.length) {
        return false;
    }
    const settingsComponentsRoles = [
        UserRoleEnum.SystemAdmin,
        UserRoleEnum.InfrastructureAdministrator,
    ];

    return _currentUserRoles.some(role => settingsComponentsRoles.includes(role.role_name));
}

// Check if the user can access users
export function canAccessUsers(_currentUserRoles: any): boolean {
    if (!_currentUserRoles.length) {
        return false;
    }

    const userComponentsRoles = [
        UserRoleEnum.SystemAdmin,
    ];

    return _currentUserRoles.some(role => userComponentsRoles.includes(role.role_name));
}


// Check if the user can access all items campaigns
export function canAccessAllItemsCampaign(_currentUserRoles: any): boolean {
    if (!_currentUserRoles.length) {
        return false;
    }

    const allItemsCampaignRoles = [
        UserRoleEnum.SystemAdmin,
        UserRoleEnum.ContentApprover,
    ];

    return _currentUserRoles.some(role => allItemsCampaignRoles.includes(role.role_name));
}