export const versionDate = '17-Oct-2024';

export enum AngularLinkOptions {
    Local = 'http://localhost:4200',
    Development = 'https://dev-intracast.smartsbusines.com',
    QA = 'https://qa-intracast.smartsbusines.com',
    Production = 'https://intracast.smartsbusines.com'
}

export enum StrapiEnvironment {
    Local = 'http://localhost:1337',
    IPDevelopment = 'http://20.80.19.76:1337',
    IPQA = 'http://20.80.19.76:1338',
    Development = 'https://devcms-intracast.smartsbusines.com',
    QA = 'https://qacms-intracast.smartsbusines.com',
    Production = 'https://cms-intracast.smartsbusines.com',
    webSocketLocal = 'ws://localhost:1337',
    webSocketDevelopment = 'wss://devcms-intracast.smartsbusines.com/',
}